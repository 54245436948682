import { All } from "@/router";
export default [
    {
        title: "الرئيسية",
        route: "/admin",
        icon: "home-alt",
        roles: [All]
    },
    {
        title: "رسالة جماعية",
        route: "/send",
        icon: "envelope",
        roles: [All]
    },
    {
        title: "الإعدادات",
        route: "/settings",
        icon: "setting",
        roles: [All]
    },
];
