<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- <app-navbar-vertical-layout class="ml-auto" /> -->

    <!-- Left Col -->
    <!-- <app-navbar-vertical-layout /> -->
    <b-navbar-nav class="nav align-items-center ml-auto">
       <!-- <b-button
        @click="changeDashLang(locales[+activeIndex].locale)"
        variant="flat-dark"
        class="btn-icon rounded border-0"
        style="font-size: 12px"
      >
        {{ locales[+activeIndex].name }}
      </b-button> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav
} from 'bootstrap-vue'
// import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
 import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";

export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    // AppNavbarVerticalLayout,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
